import { cloneDeep } from "lodash";
// import { useRoundsContext } from "../../../components/contexts/RoundsContext";
import { useUserContext } from "../../../components/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { distanceCalculator, paceCalculator } from "../tools/tools";
import { convertFeetToKilometers, convertFeetToMiles } from "../utils/utils";

const formatDate = (dateString) => {
  // This is more human readable, but commenting this for the sake of searching
  // const date = new Date(dateString);
  // return date.toLocaleDateString('en-US', {
  //   year: 'numeric',
  //   month: 'long',
  //   day: 'numeric',
  // });
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const TableRow = ({
  index,
  date,
  course,
  SGS,
  strokes,
  minutes,
  seconds,
  openDeleteDialog,
  strokesToPar,
  timeToPar,
  distance,
  distanceUnit,
  pace
}) => {
  // let { rounds, setRounds } = useRoundsContext();
  // const { user } = useUserContext();
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  const editRoundHandler = (index) => {
    navigate(`/rounds/editRound/${index}`);
  };



  
  pace = paceCalculator(pace, distanceUnit);
  if (pace == null || distance == "Null") {
    pace = "Null";
  }

  // const distanceCalculator = () => {
  //   let output = null;
  //   if (typeof distance === 'number' && !isNaN(distance)) {
  //     if (distanceUnit === "km") {
  //       output = convertFeetToKilometers(distance);
  //     } else if (distanceUnit) {
  //       output = convertFeetToMiles(distance);
  //     }
  //     // kilometer function is already returning 2 decimal places
  //     if (typeof distance === 'number' && !isNaN(distance) && distance != 0) {
  //       // console.log('output', output)
  //       if ( distanceUnit !== 'km')
  //       output = output.toFixed(2);
  //     } else {
  //       output = "Null";
  //     }
  //   } else {
  //     output = "Null";
  //   }
  //   // console.log('output', output, distanceUnit, convertFeetToMiles(distance))
  //   return output;
  // }

  
  const getDistance = () => {
    const output = distanceCalculator(distance, distanceUnit);
    if (output === "Null") {
      return "Null";
    } else {
      return `${output} ${distanceUnit}`;
    }
  }



  const computeTimePar = () => {
    let negative = false;
    if (timeToPar == null) return "";
    if (timeToPar < 0) {
      negative = true;
      timeToPar = -1 * timeToPar;
    }
    if (timeToPar == 0) return "(ZERO)";
    const minutes = parseInt(timeToPar / 60);
    const seconds = parseInt(timeToPar % 60);
    return `(${negative ? '-' : '+'}${minutes}:${seconds.toString().padStart(2, "0")})`;
  };

  const computeStrokesToPar = () => { 
    //console.log('strokesToPar', strokesToPar)
    if (strokesToPar == 0) return '(ZERO)'
    else if (strokesToPar > 0) return `(+${strokesToPar})`
    else if (strokesToPar < 0) return `(${strokesToPar})`
    else if (!strokesToPar) return 'Null'
    return `(${strokesToPar})`
  }

  const computeSGSToPar = () => {
    // let timeToParString = computeTimePar();
    // let strokesToParString = computeStrokesToPar();
    // if (!timeToParString.length || !strokesToParString.length) return '';
    let negative = false;
    if (timeToPar < 0) {
      negative = true;
      timeToPar = -1 * timeToPar;
    }
    let minutes = parseInt(timeToPar / 60);
    if (negative) minutes = -1 * minutes;
    const seconds = parseInt(timeToPar % 60);
    if (strokesToPar + minutes < 0) negative = true;
    else negative = false;
    return `(${negative ? '' : '+'}${strokesToPar + minutes}:${seconds.toString().padStart(2, "0")})`;
  }

  return (
    <tr
      id={`r-${index + 1}`}
      className='row-item'
      key={index}
      onClick={() => {
        editRoundHandler(index);
      }}
      style={{ cursor: "pointer" }}>
      <td>
        {(() => {
          return formatDate(date);
        })()}
      </td>
      <td>{course}</td>
      <td>
        {`${strokes} ${computeStrokesToPar()}`}
      </td>
      <td>{`${minutes}:${seconds} ${computeTimePar()}`}</td>
      {/* <td>{`${minutes}:${seconds} (${timeToPar})`}</td> */}
      <td>
        {/* {SGS} ({strokes} in {minutes}:{seconds.toString().padStart(2, "0")}) */}
        {SGS}  {computeSGSToPar()}
      </td>
      <td>
        {/* {distance} {distanceUnit} */}
        {getDistance()}
      </td>
      <td>{pace}</td>
      {/* <td>
        <button
          aria-label='View and Edit Round'
          onClick={() => {
            editRoundHandler(index);
          }}>
          <span className='fas fa-eye' aria-hidden='true'></span>
          &nbsp;
          <span className='fas fa-edit' aria-hidden='true'></span>
        </button>
      </td>
      <td>
        <button aria-label='Delete Round' onClick={openDeleteDialog}>
          <span className='fas fa-trash' aria-hidden='true'></span>
        </button>
      </td> */}
    </tr>
  );
};

export default TableRow;
