import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../components/contexts/UserContext";
import NoRoundsMessage from "../components/NoRoundsMessage";
import TableRow from "../components/TableRow";
// import { useRoundsContext } from "../../../components/contexts/RoundsContext";
import { sortRounds } from "../../../services/roundsServices";

import { searchRounds } from "../../../services/roundsServices";
import { intersectionBy } from "lodash";
import ModalDialog from "../../shared/ModalDialog";
import { Bounce, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setRounds } from "../roundSlice";
import { deleteRoundAction, fetchAllRoundsAction } from "../roundActions";

const paddingLeftZero = {
  paddingLeft: "0px"
}

const Rounds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { user } = useUserContext();
  const {user, authenticated} = useSelector(state => state.user);
  const distanceUnit = useSelector(state => {
    try {
      const unit = state.user.user.preferences.preferredUnits
      if (unit === "imperial") {
        return "miles";
      } else {
        return "km";
      }
    } catch (e) {
      return 'mi'
    }

  })
  // const { rounds, setRounds } = useRoundsContext();
  const rounds = useSelector(state => state.rounds);
  // State to keep track of sorting configuration
  const [sortConfig, setSortConfig] = useState({
    columnName: null,
    direction: "none"
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRounds, setFilteredRounds] = useState(rounds);
  // const [deleteDialogId, setDeleteDialogId] = useState(null);

  useEffect(() => {
    setFilteredRounds(prev => intersectionBy(prev, rounds, '_id'));
  }, [rounds]);

  useEffect(() => {
    if (!authenticated) navigate("/login");
  }, [authenticated])

  useEffect(() => {
    dispatch(fetchAllRoundsAction(navigate));
  }, [])

  // let userAuthenticated = user.accountInfo.authenticated;
  const userAuthenticated = useSelector(state => state.user.authenticated);

  //TODO Added disabled button for now, not called 
  const newRoundClickHandler = () => {
    navigate("/rounds/newRound");
  };
  
  
  let storedUser = null;
  if (userAuthenticated) {
    storedUser = JSON.parse(localStorage.getItem(user.accountInfo.email));
  }

  const handleSort = columnName => {
    let direction = "ascending";
    if (sortConfig.columnName === columnName && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ columnName, direction });

    const sortedData = sortRounds(rounds, columnName, direction);
    // setRounds(sortedData);
    dispatch(setRounds(sortedData));
  };

  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchTerm(event.target.value);
    if (value) {
      const filteredRounds = searchRounds(rounds, event.target.value, distanceUnit);
      setFilteredRounds(filteredRounds);
    } else {
      setFilteredRounds(rounds);
    }
  };

  const getSortIconClass = columnName => {
    let defaultIconClass = "fas fa-sort sort-icon";
    if (sortConfig.columnName === columnName) {
      switch (sortConfig.direction) {
        case "descending":
          return "fas fa-sort-amount-down sort-icon";
        case "ascending":
          return "fas fa-sort-amount-up sort-icon";
        default:
          break;
      }
    }
    return defaultIconClass;
  };

  const deleteRoundHandler = index => {
    // let storedUser = JSON.parse(localStorage.getItem(user.accountInfo.email));
    // storedUser.rounds = storedUser.rounds.filter(({ roundNum }) => roundNum !== index);
    // localStorage.setItem(user.accountInfo.email, JSON.stringify(storedUser));
    // // setRounds(storedUser.rounds);
    // dispatch(setRounds(storedUser.rounds));
    // toast.error("Deleted round successfully", {
    //   position: "top-center",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored",
    //   transition: Bounce
    // });
    dispatch(deleteRoundAction(index, navigate));
  };

  return (
    <>
      {!userAuthenticated && <h1>Loading</h1>}
      {userAuthenticated && (
        <div
          id='roundsModeTab'
          // className="mode-page hidden"
          className='mode-page'
          role='tabpanel'
          aria-label='Rounds Tab'
          tabIndex='0'
        >
          <h1 className='mode-page-header'>Rounds </h1>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='me-2'>Search/Filter:</div>
            <div className='w-25'>
              <input
                type='text'
                className='form-control'
                placeholder=''
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div
            id='roundUpdated'
            className='toast-container hidden'
            role='alert'
            aria-atomic='true'
            aria-live='assertive'
          >
            <div id='roundUpdatedMsg' className='toast-text'>
              New round was logged
            </div>
            <button id='roundUpdatedClose' type='button' className='btn-close toast-close' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <table id='roundsTable' className='table table-hover'>
            <caption id='roundsTableCaption' aria-live='polite'>
              Displaying {filteredRounds.length} of {rounds.length} speedgolf rounds
            </caption>
            <thead className='table-light'>
              <tr>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by date'
                    onClick={() => handleSort("date")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("date")}></span>
                  </button>
                  Date
                </th>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className={`btn bg-transparent table-sort-btn`}
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("course")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("course")}></span>
                  </button>
                  Course
                </th>
                <th>
                <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("strokes")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("strokes")}></span>
                  </button>
                  Strokes
                </th>
                <th>
                <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("time")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("time")}></span>
                  </button>
                  Time
                </th>
                <th scope='col' role='columnheader' className='sortable-header cell-align-middle' aria-sort='none'>
                  <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by score'
                    onClick={() => handleSort("SGS")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("SGS")}></span>
                  </button>
                  SGS
                </th>
                <th>
                <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("distance")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("distance")}></span>
                  </button>
                  Distance
                </th>
                <th>
                <button
                    className='btn bg-transparent table-sort-btn'
                    aria-label='Sort ascending by course'
                    onClick={() => handleSort("pace")}
                    style={paddingLeftZero}
                  >
                    <span className={getSortIconClass("pace")}></span>
                  </button>
                  Pace
                </th>
                {/* <th scope='col' className='cell-align-middle'>
                  View/Edit...
                </th>
                <th scope='col' className='cell-align-middle'>
                  Delete
                </th> */}
              </tr>
            </thead>
            <tbody>
              {searchTerm ? (
                filteredRounds.length === 0 ? (
                  <NoRoundsMessage />
                ) : (
                  filteredRounds.map((element, index) => (
                    <TableRow
                      index={element._id}
                      date={element.date}
                      course={element.courseId.shortName}
                      minutes={Math.floor(element.time / 60)}
                      seconds={element.time % 60}
                      SGS={element.SGS}
                      strokes={element.strokes}
                      key={element._id}
                      strokesToPar={element.strokesToPar}
                      timeToPar={element.timeToPar}
                      distance={element.distance}
                      distanceUnit={distanceUnit}
                      pace={element.pace}
                      // openDeleteDialog={() => setDeleteDialogId(element._id)}
                      openDeleteDialog={() => {}}
                    />
                  ))
                )
              ) : (
                rounds.map((element, index) => (
                  <TableRow
                    index={element._id}
                    date={element.date}
                    course={element?.courseId?.shortName ?? "Unknown"}
                    minutes={Math.floor(element.time / 60)}
                    seconds={element.time % 60}
                    SGS={element.SGS}
                    strokes={element.strokes}
                    key={element._id}
                    strokesToPar={element.strokesToPar}
                    timeToPar={element.timeToPar}
                    distance={element.distance}
                    distanceUnit={distanceUnit}
                    pace={element.pace}
                    // openDeleteDialog={() => setDeleteDialogId(element._id)}
                    openDeleteDialog={() => {}}
                  />
                ))
              )}
            </tbody>
          </table>
          {/* <ModalDialog
            isOpen={deleteDialogId}
            title="Delete Round?"
            body="Do you really want to delete that round?"
            actionBtnText="Yes, Delete Round"
            cancelBtnText="No, Cancel"
            close={() => setDeleteDialogId(null)}
            onSubmit={() => deleteRoundHandler(deleteDialogId)}
          /> */}
          <button id='roundsModeActionBtn' type='button' className='float-btn' onClick={newRoundClickHandler}
          >
            <span className='fas fa-calendar-plus fa-fw' aria-hidden='true'></span>
            New Round
          </button>
        </div>
      )}
    </>
  );
};

export default Rounds;
